import { UseFormMethods } from 'react-hook-form';
import React, { Dispatch, SetStateAction } from 'react';
import { InformationFormProps } from '@/types/form/informationFormProps';

export default class CustomerNameService {
  static handleFirstNameBlur = (setShowFirstNameError: Dispatch<SetStateAction<boolean>>) => {
    setShowFirstNameError(true);
  };

  static handleLastNameBlur = (setShowLastNameError: Dispatch<SetStateAction<boolean>>) => {
    setShowLastNameError(true);
  };

  static blurField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
  };

  static handleFirstNameFocus = (setShowFirstNameError: Dispatch<SetStateAction<boolean>>, formMethods: UseFormMethods<InformationFormProps>) => {
    setShowFirstNameError(false);
    formMethods.clearErrors('duplicationAlert');
  };

  static handleLastNameFocus = (setShowLastNameError: Dispatch<SetStateAction<boolean>>, formMethods: UseFormMethods<InformationFormProps>) => {
    setShowLastNameError(false);
    formMethods.clearErrors('duplicationAlert');
  };
}
