import Utils from '@utils/utils';
import React, { Dispatch, SetStateAction } from 'react';
import { NameComponentWrapper } from '@pages/Identity/identity.styles';
import { UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import CustomerNameService from '@pages/CustomerName/customerName.service';
import { ProspectSource } from '@/types';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { Field } from '@/common/components/form/Field';
import { ConnectForm, Label } from '@/common/components/form/index';
import { InformationFormProps } from '@/types/form/informationFormProps';

type Props = {
  prospectSource: ProspectSource
  lastNameUserInputValue?: string,
  setLastNameUserInputValue?: Dispatch<SetStateAction<string>>,
  setShowLastNameError?: Dispatch<SetStateAction<boolean>>,
  formMethods?: UseFormMethods<InformationFormProps>,
  isDisabled: boolean,
  isDuplicationTested: boolean
}

export const LastName: React.FC<Props> = ({ prospectSource, lastNameUserInputValue, setLastNameUserInputValue, setShowLastNameError, formMethods, isDisabled, isDuplicationTested }) => (
  <ConnectForm>
    {({ register, customer, t, getValues }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => (
      <NameComponentWrapper>
        <Label htmlFor="lastName">{t('dcc.lastname')}</Label>
        {isDuplicationTested && setLastNameUserInputValue && setShowLastNameError && formMethods ? (
          <Field
            name="lastName"
            id="lastName"
            ref={register({ required: true, minLength: 1, validate: (value) => Utils.doesNotContainOnlySpaces(value) })}
            defaultValue={lastNameUserInputValue}
            autoComplete={Utils.isAutoCompleteOff(prospectSource) ? 'off' : 'given-name'}
            maxLength={50}
            onBlur={() => {
              CustomerNameService.handleLastNameBlur(setShowLastNameError);
            }}
            onFocus={() => CustomerNameService.handleLastNameFocus(setShowLastNameError, formMethods)}
            onKeyDown={(event) => CustomerNameService.blurField(event)}
            onChange={() => setLastNameUserInputValue(getValues().lastName)}
            disabled={isDisabled}
          />
        ) : (
          <Field
            name="lastName"
            id="lastName"
            ref={register({ required: true, minLength: 1, validate: (value) => Utils.doesNotContainOnlySpaces(value) })}
            defaultValue={customer.customerInfo.lastname}
            autoComplete={Utils.isAutoCompleteOff(prospectSource) ? 'off' : 'family-name'}
            maxLength={50}
            disabled={isDisabled}
          />

        )}
      </NameComponentWrapper>
    )}
  </ConnectForm>
);
