import Utils from '@utils/utils';
import React from 'react';
import { NamesWrapper } from '@pages/Identity/identity.styles';
import { LastName } from '@common/components/form/information/LastName';
import { FirstName } from '@common/components/form/information/FirstName';
import { FuriganaLastName } from '@common/components/form/information/FuriganaLastName';
import { FuriganaFirstName } from '@common/components/form/information/FuriganaFirstName';
import { MiddleName } from '@common/components/form/information/MiddleName';
import { useStateMachine } from 'little-state-machine';
import { ProspectSource } from '@/types';

export const Names: React.FC = () => {
  const { state } = useStateMachine();
  const { customer, translations, prospectSource } = state;
  const { lightLanguageCode } = translations;
  const { customerContact: { address: { country } } } = customer;

  return Utils.isFirstNameAndLastNameReversed(lightLanguageCode, country)
    ? (
      <>
        <NamesWrapper $prospectSource={prospectSource}>
          <LastName prospectSource={prospectSource} isDisabled={false} isDuplicationTested={false} />
          {prospectSource === ProspectSource.UPDATE_NEW_POS_CUSTOMER && <MiddleName prospectSource={prospectSource} />}
          <FirstName prospectSource={prospectSource} isDisabled={false} isDuplicationTested={false} />
        </NamesWrapper>
        <NamesWrapper $prospectSource={prospectSource}>
          <FuriganaLastName customerCountry={country} />
          <FuriganaFirstName customerCountry={country} />
        </NamesWrapper>
      </>
    ) : (
      <>
        <NamesWrapper $prospectSource={prospectSource}>
          <FirstName prospectSource={prospectSource} isDisabled={false} isDuplicationTested={false} />
          {prospectSource === ProspectSource.UPDATE_NEW_POS_CUSTOMER && <MiddleName prospectSource={prospectSource} />}
          <LastName prospectSource={prospectSource} isDisabled={false} isDuplicationTested={false} />
        </NamesWrapper>
        <NamesWrapper $prospectSource={prospectSource}>
          <FuriganaFirstName customerCountry={customer.customerContact.address.country} />
          <FuriganaLastName customerCountry={customer.customerContact.address.country} />
        </NamesWrapper>
      </>
    );
};
