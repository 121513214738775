import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import Utils from '@utils/utils';
import { ZipCode } from '@pages/Address/components/ZipCode';
import { City } from '@pages/Address/components/City';
import { Street } from '@pages/Address/components/Street';
import { PoBox } from '@pages/Address/components/PoBox';
import { NamesWrapper } from '@pages/Identity/identity.styles';
import { useLastPageVisited, useScrollToTop, useThemeColor, useTranslation } from '@/services/hooks';
import { PageID } from '@/types/enum/pageID';
import FlowService from '@/services/flow.service';
import PageContainer from '@/common/components/containers/PageContainer';
import { Label } from '@/common/components/form';
import { ButtonContainer, FormContainer } from '@/common/components/containers';
import api from '@/api';
import {
  updateAlreadyExistingCustomer,
  updateAlreadyRegistered,
  updateCustomerInformation,
  updateErrorTypeOnError,
} from '@/littleStateMachine/actions';
import { Field } from '@/common/components/form/Field';
import { Country, TYPE_DROPDOWN } from '@/types';
import mp from '@/services/mixpanel/mixpanel.service';
import { FullPageLoader } from '@/common/components/Loader';
import FormHeader from '@/common/components/form/FormHeader';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { ThemeType } from '@/types/enum/themeType';
import { Title } from '@/common/components';
import { LastName } from '@/common/components/form/information/LastName';
import { FirstName } from '@/common/components/form/information/FirstName';
import ErrorMessage from '@/common/components/form/ErrorMessage';

const UpdateAddress: React.FunctionComponent = () => {
  useScrollToTop();
  useLastPageVisited(PageID.UPDATE_ADDRESS);

  // Prepare navigation
  const navigate = useNavigate();

  // Prepare translations
  const t = useTranslation();

  // Retrieve current state and actions from store
  const { actions, state } = useStateMachine({ updateErrorTypeOnError, updateCustomerInformation, updateAlreadyRegistered, updateAlreadyExistingCustomer });
  const { brand, customer, prospectSource, flow, countries, translations, languages, mixPanelToken, actualToken } = state;
  const { defaultLogo, style: { colors } } = brand;
  const defaultLogoUrl = defaultLogo.logoUrl;
  useThemeColor(brand, ThemeType.FORM);

  const customerCountryCode = customer.customerContact.address.country;
  const customerStateCode = customer.customerContact.address.state;
  const currentCountry = countries.find((country: Country) => country.code === customer.customerContact.address.country);
  const statesFromCurrentCountry = currentCountry ? currentCountry.states : [];
  if (statesFromCurrentCountry?.length > 0 && !customerStateCode) navigate('/error');

  // current page
  const localStep = PageID.UPDATE_ADDRESS;
  const currentStep = FlowService.getCurrentStepNumber(state, localStep);

  // Retrieve token from the URL
  const { token } = useParams<{ token: string }>();

  // Prepare the form
  const formMethods = useForm<InformationFormProps>({ mode: 'onChange' });
  const { isSubmitting, isValid } = formMethods.formState;

  const customerCountry = countries.find((country: Country) => country.code === customer.customerContact.address.country);

  const [isLoading, setIsLoading] = useState(false);
  const [showZipCodeError, setShowZipCodeError] = useState(false);
  const [showCityError, setShowCityError] = useState(false);
  const [showStreetError, setShowStreetError] = useState(false);

  useEffect(() => {
    mp.init(mixPanelToken, actualToken, null);
    mp.pageView(localStep);
    (async function fetch() {
      if (brand.code && flow.authorizedPages.includes(localStep)) {
        try {
          setIsLoading(true);
          const { data } = await api.getPartialUpdateInformation(token);
          actions.updateAlreadyRegistered(true);
          actions.updateAlreadyExistingCustomer(data);
          setIsLoading(false);
        } catch (err) {
          if (err?.response?.data) {
            actions.updateErrorTypeOnError(err.response.data);
          }
          navigate('/error');
        }
      } else {
        navigate(`/${token}`);
      }
    }());
  }, [navigate, token]);

  const onSubmit = formMethods.handleSubmit(async ({
    street, city, zipcode, pobox,
  }) => {
    const addressInfos = { street, city, zipcode, pobox };
    actions.updateCustomerInformation(addressInfos);
    mp.saveAddress(addressInfos);
    const nextPage = FlowService.nextPage(state, localStep);
    navigate(`/${token}/${nextPage}`);
  });

  if (brand && flow.authorizedPages.includes(localStep)) {
    return (
      <ThemeProvider theme={{ colors, brand: brand.code, prospectSource }}>
        { isLoading ? <FullPageLoader />
          : (
            <PageContainer>
              <FormHeader
                numberOfSteps={FlowService.getTotalNumberOfSteps(state)}
                currentStep={currentStep}
                languages={languages}
                type={TYPE_DROPDOWN.DEFAULT}
                defaultLogoUrl={defaultLogoUrl}
              />
              <Title>{t('dcc.updateAddressQuestion')}</Title>
              <FormProvider {...formMethods}>
                <form>
                  <FormContainer onKeyDown={(event) => Utils.validateForm(event, isValid, onSubmit)}>
                    <NamesWrapper>
                      <LastName prospectSource={prospectSource} isDisabled isDuplicationTested={false} />
                      <FirstName prospectSource={prospectSource} isDisabled isDuplicationTested={false} />
                    </NamesWrapper>
                    <Label htmlFor="Country">{t('dcc.countryLabel')}</Label>
                    <Field
                      name="selectCountry"
                      id="selectCountry"
                      ref={formMethods.register({ required: true, minLength: 1 })}
                      defaultValue={customerCountryCode ? t(`country.${customerCountryCode.toLowerCase()}`) : ''}
                      maxLength={0}
                      disabled
                    />
                    {Boolean(statesFromCurrentCountry.length) && (
                    <>
                      <Label htmlFor="State">{customerCountry?.hasStates ? t('dcc.state') : t('dcc.province')}</Label>
                      <Field
                        name="selectState"
                        id="selectState"
                        ref={formMethods.register({ required: true, minLength: 1 })}
                        defaultValue={t(customerStateCode ? `state.${customerStateCode}` : '')}
                        maxLength={0}
                        disabled
                      />
                    </>
                    )}
                    { // the normal if/else workflow breaks the mandatory state of fields. Due to the form control of react-hook-form ?
                      customerCountry && Utils.isAddressReversed(translations.lightLanguageCode, customerCountry.code)
                        && (
                          <>
                            <ZipCode prospectSource={prospectSource} currentCountry={customerCountry} showZipCodeError={showZipCodeError} setShowZipCodeError={setShowZipCodeError} />
                            <City prospectSource={prospectSource} showCityError={showCityError} setShowCityError={setShowCityError} />
                            <Street prospectSource={prospectSource} showStreetError={showStreetError} setShowStreetError={setShowStreetError} />
                            <PoBox currentCountry={customerCountry} />
                            {((showZipCodeError && formMethods.errors?.zipcode) || (showCityError && formMethods.errors?.city) || (showStreetError && formMethods.errors?.street)) && <ErrorMessage id="error">{t('web.invalidFormat')}</ErrorMessage>}
                          </>
                        )
                      }
                    {
                        customerCountry && !Utils.isAddressReversed(translations.lightLanguageCode, customerCountry.code)
                        && (
                          <>
                            <Street prospectSource={prospectSource} showStreetError={showStreetError} setShowStreetError={setShowStreetError} />
                            <PoBox currentCountry={customerCountry} />
                            <City prospectSource={prospectSource} showCityError={showCityError} setShowCityError={setShowCityError} />
                            <ZipCode prospectSource={prospectSource} currentCountry={customerCountry} showZipCodeError={showZipCodeError} setShowZipCodeError={setShowZipCodeError} />
                            {((showZipCodeError && formMethods.errors?.zipcode) || (showCityError && formMethods.errors?.city) || (showStreetError && formMethods.errors?.street)) && <ErrorMessage id="error">{t('web.invalidFormat')}</ErrorMessage>}
                          </>
                        )
                      }
                  </FormContainer>
                  <ButtonContainer isValid={isValid} isSubmitting={isSubmitting} pageId={PageID.UPDATE_ADDRESS} onSubmit={onSubmit} />
                </form>
              </FormProvider>
            </PageContainer>
          )}
      </ThemeProvider>
    );
  }
  return <Navigate to={`/${token}`} replace />;
};

export default UpdateAddress;
