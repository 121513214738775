import Utils from '@utils/utils';
import React, { Dispatch, SetStateAction } from 'react';
import { NameComponentWrapper } from '@pages/Identity/identity.styles';
import { UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import CustomerNameService from '@pages/CustomerName/customerName.service';
import { ProspectSource } from '@/types';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { Field } from '@/common/components/form/Field';
import { ConnectForm, Label } from '@/common/components/form/index';
import { InformationFormProps } from '@/types/form/informationFormProps';

type Props = {
  prospectSource: ProspectSource
  firstNameUserInputValue?: string,
  setFirstNameUserInputValue?: Dispatch<SetStateAction<string>>,
  setShowFirstNameError?: Dispatch<SetStateAction<boolean>>,
  formMethods?: UseFormMethods<InformationFormProps>,
  isDisabled: boolean,
  isDuplicationTested: boolean
}

export const FirstName: React.FC<Props> = ({ prospectSource, firstNameUserInputValue, setFirstNameUserInputValue, setShowFirstNameError, formMethods, isDisabled, isDuplicationTested }) => (
  <ConnectForm>
    {({ register, customer, t, getValues }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => (
      <NameComponentWrapper>
        <Label htmlFor="firstName">{t('dcc.firstname')}</Label>
        {isDuplicationTested && setFirstNameUserInputValue && setShowFirstNameError && formMethods ? (
          <Field
            name="firstName"
            id="firstName"
            ref={register({ required: true, minLength: 1, validate: (value) => Utils.doesNotContainOnlySpaces(value) })}
            defaultValue={firstNameUserInputValue}
            autoComplete={Utils.isAutoCompleteOff(prospectSource) ? 'off' : 'given-name'}
            maxLength={50}
            onBlur={() => {
              CustomerNameService.handleFirstNameBlur(setShowFirstNameError);
            }}
            onFocus={() => CustomerNameService.handleFirstNameFocus(setShowFirstNameError, formMethods)}
            onKeyDown={(event) => CustomerNameService.blurField(event)}
            onChange={() => setFirstNameUserInputValue(getValues().firstName)}
            disabled={isDisabled}
          />
        ) : (
          <Field
            name="firstName"
            id="firstName"
            ref={register({ required: true, minLength: 1, validate: (value) => Utils.doesNotContainOnlySpaces(value) })}
            defaultValue={customer.customerInfo.firstname}
            autoComplete={Utils.isAutoCompleteOff(prospectSource) ? 'off' : 'given-name'}
            maxLength={50}
            disabled={isDisabled}
          />
        )}
      </NameComponentWrapper>
    )}
  </ConnectForm>
);
